import axios from "axios"
export default {
  namespaced: true,
  state: {
    tokens: [],
    tokenData: [],
    isLoadingTable: false
  },
  getters: {},
  mutations: {
    setTokenData(state, tokenList) {
      state.tokens = tokenList
    },
    setSingleTokenData(state, tokenData) {
      state.tokenData = tokenData
    },
    setTableLoading(state, _isLoading) {
      state.isLoadingTable = _isLoading;
    }
  },
  actions: {
    async getTokenData({ commit }, { page, limit, filterField, direction }) {

      console.log('filterField', filterField)
      console.log('direction', direction)
      commit('setTableLoading', true);
      const params = new URLSearchParams([
        ['page', page],
        ['limit', limit],
        ['filter', filterField ? filterField : ''],
        ['direction', direction ? direction : ''],
      ])

      const endpoint = process.env.VUE_APP_BASE_URL_APE + 'contract-events/tokens';
      const response = await axios.get(endpoint, { params });
      if (response.status === 200) {
        const tokenList = response.data.payload.items || [];
        commit('setTokenData', tokenList);
      }

      commit('setTableLoading', false);
    },
    async getSingleTokenData({ commit }, { token }) {

      commit('loaders/SET_LOADING', true, { root: true });

      const endpoint = process.env.VUE_APP_BASE_URL_APE + `contract-events/token/${token}`;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        console.log('response', response.data.payload);
        const tokenData = response.data.payload || [];
        commit('setSingleTokenData', tokenData);

        commit('loaders/SET_LOADING', false, { root: true });
      }
    }
  }
}