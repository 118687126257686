import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [{
            path: '/',
            name: 'home',
            component: () =>
                import ('@/views/Home.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Home',
                breadcrumb: [{
                    text: 'Home',
                    active: true,
                }, ],
            },
        },
        {
            path: '/details/:id',
            name: 'details',
            component: () =>
                import ('@/views/crypto/CryptoDetails.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Crypto Details Page',
                breadcrumb: [{
                    text: 'Crypto Details',
                    active: true,
                }, ],
            },
        },
        {
            path: '/earn-with-us',
            name: 'earn-with-us',
            component: () =>
                import ('@/views/EarnWithUs.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'earn with us',
                breadcrumb: [{
                    text: 'earn with us',
                    active: true,
                }, ],
            },
        },
        {
            path: '/add-coin',
            name: 'add-coin',
            component: () =>
                import ('@/views/crypto/AddCoin.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Add coin page',
                breadcrumb: [{
                    text: 'Add coin page',
                    active: true,
                }, ],
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: () =>
                import ('@/views/auth/Profile.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Profile page',
                breadcrumb: [{
                    text: 'Profile',
                    active: true,
                }, ],
            },
        },
        {
            path: '/news-letter',
            name: 'news-letter',
            component: () =>
                import ('@/views/crypto/NewsLetter.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'News Letter Page',
                breadcrumb: [{
                    text: 'News Letter',
                    active: true,
                }, ],
            },
        },
        {
            path: '/audit-coin',
            name: 'audit-coin',
            component: () =>
                import ('@/views/AuditCoin.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Audit Coin Page',
                breadcrumb: [{
                    text: 'Audit Coin',
                    active: true,
                }, ],
            },
        },
        {
            path: '/presale',
            name: 'presale',
            component: () =>
                import ('@/views/crypto/Presale.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Presale Page',
                breadcrumb: [{
                    text: 'Presale',
                    active: true,
                }, ],
            },
        },
        {
            path: '/services-we-offer',
            name: 'services-we-offer',
            component: () =>
                import ('@/views/ServicesWeOffer.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Services Page',
                breadcrumb: [{
                    text: 'services-we-offer',
                    active: true,
                }, ],
            },
        },
        {
            path: '/our-services',
            name: 'our-services',
            component: () =>
                import ('@/views/ourServises.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Services Page',
                breadcrumb: [{
                    text: 'our-services',
                    active: true,
                }, ],
            },
        },
        {
            path: '/our-services/marketing-services',
            name: 'marketing-services',
            component: () =>
                import ('@/views/servises/MarketingServises.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Marketing Services Page',
                breadcrumb: [{
                    text: 'marketing-services',
                    active: true,
                }, ],
            },
        },
        {
            path: '/our-services/development-services',
            name: 'development-services',
            component: () =>
                import ('@/views/servises/DevelopmentServises.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Development Services Page',
                breadcrumb: [{
                    text: 'Development-services',
                    active: true,
                }, ],
            },
        },
        {
            path: '/our-services/token-audits',
            name: 'token-audits',
            component: () =>
                import ('@/views/servises/TokenAudits.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Token Audits Page',
                breadcrumb: [{
                    text: 'Token Audits',
                    active: true,
                }, ],
            },
        },
        {
            path: '/our-services/company-registration',
            name: 'company-registration',
            component: () =>
                import ('@/views/servises/CompanyRegistration.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'CompanyRegistration Page',
                breadcrumb: [{
                    text: 'CompanyRegistration',
                    active: true,
                }, ],
            },
        },
        {
            path: '/promote',
            name: 'promote',
            component: () =>
                import ('@/views/crypto/Promote.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Promote Page',
                breadcrumb: [{
                    text: 'Promote',
                    active: true,
                }, ],
            },
        },
        {
            path: '/contest',
            name: 'contest',
            component: () =>
                import ('@/views/ContestPage.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Contest Page',
                breadcrumb: [{
                    text: 'Contest',
                    active: true,
                }, ],
            },
        },
        {
            path: '/terms-n-conditions',
            name: 'terms-n-conditions',
            component: () =>
                import ('@/views/TermsAndConditions.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Terms And Conditions Page',
                breadcrumb: [{
                    text: 'Terms And Conditions',
                    active: true,
                }, ],
            },
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: () =>
                import ('@/views/PrivacyPolicy.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Privacy Policy Page',
                breadcrumb: [{
                    text: 'Privacy Policy',
                    active: true,
                }, ],
            },
        },
        {
            path: '/calender',
            name: 'calender',
            component: () =>
                import ('@/views/Calender.vue'),
            meta: {
                requiresAuth: false,
                pageTitle: 'Calender Page',
                breadcrumb: [{
                    text: 'Calender',
                    active: true,
                }, ],
            },
        },
        {
            path: '/events/:date',
            name: 'events-view',
            component: () => import('@/views/calendar/CalendarView.vue'),
            meta: {
              requiresAuth: false,
              pageTitle: 'events-view Page',
              breadcrumb: [
                {
                  text: 'events-view',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/ape',
            name: 'ape-coins',
            component: () => import('@/views/ApeNewCoins.vue'),
            meta: {
              requiresAuth: false,
              pageTitle: 'ape-coins Page',
              breadcrumb: [
                {
                  text: 'ape-coins',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/ape-details/:token',
            name: 'ape-details-page',
            component: () => import('@/views/ApeDetailsPage.vue'),
            meta: {
              pageTitle: 'Ape Details Page',
              breadcrumb: [
                {
                  text: 'Ape Details Page',
                  active: true,
                },
              ],
            },
          },
        {
            path: '/login',
            name: 'login',
            component: () =>
                import ('@/views/auth/Login.vue'),
            meta: {
                requiresAuth: false,
                layout: 'full',
            },
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import ('@/views/auth/Register.vue'),
            meta: {
                requiresAuth: false,
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () =>
                import ('@/views/error/Error404.vue'),
            meta: {
                requiresAuth: false,
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

function is_authenticated() {
    const token = localStorage.getItem("token");
    if (token == "null" || token == null || token == "") {
        return false;
    } else {
        return true;
    }
}
/**
 * Router Authentication Guard
 */
router.beforeEach((to, from, next) => {
    const withoutAuth = ["ape-details-page", "ape-coins", "login", "signup", "events-view", "details", "register", "terms-n-conditions", "contest", "privacy-policy", "news-letter", "promote", "home", "audit-coin", "services-we-offer", "leader-board", "leader-board2", "earn with us", "presale", "calender"];
    if (withoutAuth.includes(to.name)) {
        next();
    } else if (!to.meta.requiresAuth) {
        is_authenticated() ? next(to.name) : next();
    } else {
        is_authenticated() ? next() : next({ name: "login" });
    }
});

export default router