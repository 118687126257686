import axios from "axios"
export default {
  namespaced: true,
  state: {
    tokenLiquidityData: null,
    isLoading: false
  },
  getters: {},
  mutations: {
    setLoader(state, _isLoading) {
      state.isLoading = _isLoading
    },
    setTokenLiquidityData(state, _tokenLiquidityData) {
      state.tokenLiquidityData = _tokenLiquidityData
    },
  },
  actions: {
    async getTokenLiquidityInfo({ commit }, tokenAddress) {
      commit('setLoader', true);
      const endpoint = process.env.VUE_APP_BASE_URL_APE + 'contract-events/token-liquidity-data/' + tokenAddress;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        const tokenLiquidityData = response.data.payload;
        commit('setTokenLiquidityData', tokenLiquidityData);
      }
      commit('setLoader', false);
    },
  }
}