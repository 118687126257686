import axios from "axios"
export default {
  namespaced: true,
  state: {
    tokenOwnershipInfo: null,
    warnings: [],
    isLoading: false
  },
  getters: {},
  mutations: {
    setLoader(state, _isLoading) {
      state.isLoading = _isLoading
    },
    setTokenOwnershipInfo(state, _tokenOwnershipInfo) {
      state.tokenOwnershipInfo = _tokenOwnershipInfo
    },
    setTokenOwnershipWarnings(state, _warnings) {
      state.warnings = _warnings
    },
  },
  actions: {
    async getTokenOwnershipData({ commit }, tokenAddress) {
      commit('setLoader', true);
      const endpoint = process.env.VUE_APP_BASE_URL_APE + 'contract-events/get-token-ownership-data/' + tokenAddress;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        const tokenOwnershipData = response.data.payload.ownershipData;
        const warnings = response.data.payload.warnings || [];
        commit('setTokenOwnershipInfo', tokenOwnershipData);
        commit('setTokenOwnershipWarnings', warnings);
      }
      commit('setLoader', false);
    },
  }
}