import axios from "axios"
export default {
  namespaced: true,
  state: {
    recentTokenTransactions: [],
    isLoading: false
  },
  getters: {},
  mutations: {
    setLoader(state, _isLoading) {
      state.isLoading = _isLoading
    },
    setTokenRecentTransactions(state, _recentTokenTransactions) {
      state.recentTokenTransactions = _recentTokenTransactions
    },
  },
  actions: {
    async getRecentTokenTransaction({ commit }, tokenAddress) {
      commit('setLoader', true);
      const endpoint = process.env.VUE_APP_BASE_URL_APE + 'contract-events/get-token-transactions/' + tokenAddress;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        const transactionsList = response.data.payload.recentTransactionEvents || [];
        commit('setTokenRecentTransactions', transactionsList);
      }
      commit('setLoader', false);
    },
  }
}