import axios from "axios"
export default {
  namespaced: true,
  state: {
    lpHoldersList: [],
    isLiquidityLocked: false,
    isLoading: false,
    warnings: []
  },
  getters: {},
  mutations: {
    setLoader(state, _isLoading) {
      state.isLoading = _isLoading
    },
    setLiquidityLockStatus(state, _isLiquidityLocked) {
      state.isLiquidityLocked = _isLiquidityLocked
    },
    setLpTokenHoldersList(state, _lpHoldersList) {
      state.lpHoldersList = _lpHoldersList
    },
    setWarningMessages(state, _warningMessages) {
      state.warnings = _warningMessages
    },
  },
  actions: {
    async getTokenLiquidityLockData({ commit }, tokenAddress) {
      commit('setLoader', true);
      const endpoint = process.env.VUE_APP_BASE_URL_APE + 'contract-events/token-liquidity-lock-data/' + tokenAddress;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        const lpTokenHolderList = response.data.payload.lpTokenHoldersData;
        const isLiquidityLocked = response.data.payload.isLiquidityLocked;
        const warnings = response.data.payload.warnings;
        commit('setLiquidityLockStatus', isLiquidityLocked);
        commit('setLpTokenHoldersList', lpTokenHolderList);
        commit('setWarningMessages', warnings);
      }
      commit('setLoader', false);
    },
  }
}