import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import crypto from './crypto'
import loaders from './loaders'

import token from './token'
import tokenMetadata from './token-info/token-metadata'
import tokenLiquidityData from './token-info/token-Liquidity-info'
import tokenPairData from './token-info/token-pair-info'
import recentTokenTransactions from './token-info/recent-token-transactions'
import tokenHoneypotInfo from './token-info/token-honeypot-info'
import tokenLiquidityLockInfo from './token-info/token-liquidity-lock-info'
import tokenOwnershipInfo from './token-info/token-ownership-info';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    crypto,
    loaders,
    token,
    tokenMetadata,
    tokenLiquidityData,
    tokenPairData,
    recentTokenTransactions,
    tokenHoneypotInfo,
    tokenLiquidityLockInfo,
    tokenOwnershipInfo
  },
  strict: process.env.DEV,
})
