import axios from "axios"
export default {
  namespaced: true,
  state: {
    honeypotData: null,
    isLoading: false,
    warnings: []
  },
  getters: {},
  mutations: {
    setLoader(state, _isLoading) {
      state.isLoading = _isLoading
    },
    setTokenHoneypotData(state, _tokenHoneypotData) {
      state.honeypotData = _tokenHoneypotData
    },
    setWarningMessages(state, _warningMessages) {
      state.warnings = _warningMessages
    },
  },
  actions: {
    async getTokenHoneypotData({ commit }, tokenAddress) {
      commit('setLoader', true);
      const endpoint = process.env.VUE_APP_BASE_URL_APE + 'contract-events/token-honeypot-scan/' + tokenAddress;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        const honeypotData = response.data.payload.data;
        const warnings = response.data.payload.warnings;
        commit('setTokenHoneypotData', honeypotData);
        commit('setWarningMessages', warnings);
      }
      commit('setLoader', false);
    },
  }
}