import axios from "axios"
export default {
  namespaced: true,
  state: {
    tokenPairData: null,
    isLoading: false
  },
  getters: {},
  mutations: {
    setLoader(state, _isLoading) {
      state.isLoading = _isLoading
    },
    setTokenPairData(state, _tokenPairData) {
      state.tokenPairData = _tokenPairData
    },
  },
  actions: {
    async getTokenPairData({ commit }, tokenAddress) {
      commit('setLoader', true);
      const endpoint = process.env.VUE_APP_BASE_URL_APE + 'contract-events/token-pair-data/' + tokenAddress;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        const tokenPairData = response.data.payload.pairDetails;
        commit('setTokenPairData', tokenPairData);
      }
      commit('setLoader', false);
    },
  }
}