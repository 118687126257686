import axios from "axios"
export default {
  namespaced: true,
  state: {
    tokenMetadata: null,
    isLoading: false
  },
  getters: {},
  mutations: {
    setLoader(state, _isLoading) {
      state.isLoading = _isLoading
    },
    setTokenMetadata(state, _tokenMetadata) {
      state.tokenMetadata = _tokenMetadata
    },
  },
  actions: {
    async getTokenMetadata({ commit }, tokenAddress) {
      commit('setLoader', true);
      const endpoint = process.env.VUE_APP_BASE_URL_APE + 'contract-events/token-metadata/' + tokenAddress;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        const tokenMetadata = response.data.payload.tokenMetadata;
        commit('setTokenMetadata', tokenMetadata);
      }
      commit('setLoader', false);
    },
  }
}